<!--
 * @Author: N0ts
 * @Date: 2023-10-21 00:29:44
 * @Description: c4
 * @FilePath: /c4/src/App.vue
 * @Mail：mail@n0ts.top
-->
<template>
    <div class="box" v-show="showIndex == 0">
        <img class="c4" src="@/assets/c4.png" alt="c4" />
        <div class="screen">{{ screenText }}</div>
        <div class="btns">
            <div
                v-for="(item, index) in 12"
                :key="index"
                @click="btnClick(index)"
            ></div>
        </div>
        <div class="music">
            <audio :src="anjianMp3" controls hidden ref="anjianDom"></audio>
            <audio
                :src="bombInstallMp3"
                controls
                hidden
                ref="bombInstallDom"
            ></audio>
            <audio :src="bombingMp3" controls hidden ref="bombingDom"></audio>
            <audio :src="boomMp3" controls hidden ref="boomDom"></audio>
        </div>
    </div>
    <div class="menu">
        <div @click="show(1)">说明</div>
        <div @click="show(2)">设置</div>
        <div @click="clearBomb">重置</div>
    </div>
    <div class="info" v-show="showIndex == 1">
        <div>
            <h1>说明</h1>
            <p>按下「*」即可启动 C4；</p>
            <p>
                拆包需要输入对应密码，然后按下「#」即可确定密码进行拆除，输入过程无法看见增加难度，如果输入错误可按「*」进行删除上一位；
            </p>
            <p>进入设置可修改 C4 爆炸时间以及密码；</p>
        </div>
        <div class="btn" @click="show(0)">返回</div>
    </div>
    <div class="setting" v-show="showIndex == 2">
        <div>
            <h1>炸弹爆炸时间（秒）</h1>
            <input type="text" v-model="boomSecond" />
        </div>
        <div>
            <h1>拆包密码</h1>
            <input type="text" v-model="password" />
        </div>
        <div class="btn" @click="show(0)">返回</div>
    </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import FastClick from "fastclick";

// 展示模块
const showIndex = ref(0);
function show(i: number) {
    showIndex.value = i;
}

// 解决移动端点击延迟
FastClick.attach(document.body);

// 屏幕显示文字
const screenText = ref("");

// 按键声
const anjianDom = ref<any>(null);
const anjianMp3 = new URL(
    "@/assets/music/按键.mp3",
    import.meta.url
).toString();

// 按键映射
const keyMap = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "*", "0", "#"];

/**
 * 按键
 * @param i 按键索引
 */
function btnClick(i: number) {
    const text = keyMap[i];
    // 如果按 * 则开始下包
    if (text == "*" && !bombState) {
        return bombInstall();
    }
    if (bombState) {
        bombClick(text);
    } else {
        screenText.value += text;
    }
    // 播放
    playAudio(anjianDom.value);
}

/**
 * 播放音频
 * @param audio 音频 dom
 */
function playAudio(audio: any) {
    if (audio.currentTime > 0) {
        audio?.pause();
        audio.currentTime = 0;
    }
    audio?.play();
}

// 下包音效
const bombInstallDom = ref<any>(null);
const bombInstallMp3 = new URL(
    "@/assets/music/完整下包.mp3",
    import.meta.url
).toString();

/**
 * 下包
 */
function bombInstall() {
    screenText.value = "";
    playAudio(bombInstallDom.value);
    creenCb("7355608", 350, () => {
        setTimeout(() => {
            screenText.value = "炸弹已安放";
            bombState = true;
            bombing();
        }, 500);
    });
}

/**
 * 循环显示文字
 * @param str 文字内容
 * @param delay 延迟时间
 * @param cb 完成回调
 */
function creenCb(str: string, delay: number, cb: any) {
    if (str.length == 0) {
        return cb();
    }
    screenText.value += str[0];
    str = str.substring(1);
    setTimeout(() => {
        creenCb(str, delay, cb);
    }, delay);
}

// 滴滴声
const bombingDom = ref<any>(null);
const bombingMp3 = new URL(
    "@/assets/music/滴滴.mp3",
    import.meta.url
).toString();
// 爆炸声
const boomDom = ref<any>(null);
const boomMp3 = new URL("@/assets/music/爆炸.mp3", import.meta.url).toString();

// 爆炸时间
const boomSecond = ref(30);
// 爆炸倒计时
let cacheTime = 0;
// 滴滴声不同阶段延时
let delayTime = [1500, 1000, 500, 200];
// 当前滴滴延时
let nowDelayTime = 0;
// 秒计时器
let secondInterval: any = null;
// 滴滴计时器
let playInterval: any = null;
// 炸弹状态
let bombState = false;

/**
 * 包的滴滴
 */
function bombing() {
    // 求爆炸时间与阶段的平均值
    let cache = boomSecond.value / delayTime.length;

    // 计算每个阶段时间
    let cacheArr: Array<number> = [];
    for (let i = 1; i <= delayTime.length; i++) {
        cacheArr.push(cache * i);
    }

    // 触发第一阶段
    nowDelayTime = delayTime[0];
    console.log(`C4 进入第1阶段，delay：${nowDelayTime}`);
    bombPlay();

    // 秒计时
    secondInterval = setInterval(() => {
        cacheTime++;
        screenText.value = `爆炸倒计时：${boomSecond.value - cacheTime}秒`;
        // 包爆炸
        if (cacheTime >= boomSecond.value) {
            clearBomb();
            setTimeout(() => {
                screenText.value = `炸弹已经引爆`;
                bombState = false;
            }, 2000);
            return playAudio(boomDom.value);
        }

        // 阶段计算
        for (let i = 0; i < cacheArr.length; i++) {
            if (cacheTime == Number(cacheArr[i].toFixed(0))) {
                nowDelayTime = delayTime[i + 1];
                console.log(`C4 进入第${i + 2}阶段，delay：${nowDelayTime}`);
                return bombPlay();
            }
        }
    }, 1000);
}

/**
 * 滴滴音效循环，根据阶段延时
 */
function bombPlay() {
    clearInterval(playInterval);
    playInterval = setInterval(() => {
        playAudio(bombingDom.value);
    }, nowDelayTime);
}

/**
 * 下包后按键
 * @param text 内容
 */
let bombText = "";
let password = ref("1234567890");
function bombClick(text: string) {
    if (text == "*") {
        bombText = bombText.substring(1);
    } else if (text == "#") {
        if (bombText == password.value) {
            clearBomb();
            screenText.value = `炸弹已被拆除`;
        }
    } else {
        bombText += text;
    }
    console.log(`当前输入内容：${bombText}`);
}

/**
 * 拆除炸弹
 */
function clearBomb() {
    screenText.value = "";
    clearInterval(secondInterval);
    clearInterval(playInterval);
    cacheTime = 0;
    nowDelayTime = 0;
    bombState = false;
}
</script>

<style lang="less">
* {
    margin: 0;
    padding: 0;
}

html,
body,
#app {
    height: 100%;
    background-color: rgb(41, 44, 51);
}

.box {
    width: 500px;
    height: 800px;
    margin: 0 auto;
    position: relative;

    .c4 {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .screen {
        width: 65%;
        height: 8.8%;
        position: absolute;
        left: 22.7%;
        top: 10%;
        border-radius: 10px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.8rem;
        overflow: hidden;
        text-overflow: ellipsis;
        opacity: 0.5;
        // background-color: pink;
    }

    .btns {
        width: 36.5%;
        height: 29%;
        position: absolute;
        left: 29.5%;
        top: 49.5%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        // background-color: pink;
        opacity: 0.5;

        > div {
            width: 50px;
            height: 50px;
            // background-color: aquamarine;
            opacity: 0.5;
            margin-right: 10px;
            margin-bottom: 12px;

            &:nth-child(3n) {
                margin-right: 0;
            }
        }
    }
}

.setting,
.info {
    position: absolute;
    left: 20px;
    top: 20px;

    > div {
        margin-bottom: 20px;

        h1,
        p {
            color: white;
        }

        input {
            padding: 10px 5px;
            font-size: 1.2rem;
        }
    }
}

.menu {
    position: absolute;
    bottom: 10px;
    left: 10px;
    color: white;

    > div {
        &:hover {
            color: aquamarine;
            cursor: pointer;
        }
    }
}

.btn {
    display: inline-block;
    padding: 10px 30px;
    border-radius: 5px;
    background-color: rgb(90, 156, 248);
    color: white;
    cursor: pointer;
}
</style>
